<template>
  <q-dialog :ref="$options.name" no-esc-dismiss no-backdrop-dismiss>
    <q-card style="min-width: 500px">
      <q-card-section class="flex items-center justify-start">
        <h5>Add New Payment Method</h5>
      </q-card-section>

      <Loader v-if="loading" />

      <form @submit.prevent="submitPaymentMethodForm">
        <q-card-section>
          <p
            v-if="stripeResponseError.length > 0"
            class="text-negative text-weight-medium q-mb-sm"
          >
            {{ stripeResponseError }}
          </p>
          <div id="stripe_payment_element" />
        </q-card-section>

        <q-card-actions align="between" class="q-gutter-x-md q-pa-md">
          <q-btn
            flat
            @click="handleCancelClick"
            label="Cancel"
            color="subtle"
            v-close-popup
            :disable="submitting"
          />

          <q-btn
            type="submit"
            label="Add Payment Method"
            color="primary"
            :disable="submitting"
            :loading="submitting"
          />
        </q-card-actions>
      </form>
    </q-card>
  </q-dialog>
</template>

<script>
import axios from "axios";
import Loader from "@/components/UI/Loader.vue";
import { loadStripe } from "@stripe/stripe-js";

export default {
  name: "BusinessAddPaymentMethodModal",
  components: { Loader },
  props: {
    stripeReturnUrl: {
      type: String,
      default: "https://app.carscience.com/advertiser/admin/billing"
    }
  },
  emits: ["ok", "hide"],
  data() {
    return {
      loading: true,
      submitting: false,
      stripeClientSecret: "",
      stripe: null,
      stripeElements: null,
      stripeResponseError: ""
    };
  },
  mounted() {
    this.loading = true;

    this.fetchStripeClientSecret().then(() => {
      loadStripe(
        "pk_test_51Pns34HE1679HRN0xGHU8sxKuEFMbIcM5QyneN7Pn7tFxfiYHfxQqqt1lcFBNSD3NRoYMWmCROhX8cXppqnrzlR400JupRZTLk"
      ).then(stripe => {
        this.stripe = stripe;
        this.loading = false;

        let options = {
          clientSecret: this.stripeClientSecret,
          appearance: {
            theme: "flat",
            variables: {
              fontFamily:
                "Roboto, Helvetica Neue, Helvetica, Arial, sans-serif",
              colorPrimary: "#1c5eca",
              colorText: "#000",
              borderRadius: "4px",
              fontSmoothing: "always"
            }
          }
        };
        this.stripeElements = this.stripe.elements(options);
        let paymentElement = this.stripeElements.create("payment");
        paymentElement.mount("#stripe_payment_element");
      });
    });
  },
  methods: {
    show() {
      this.$refs[this.$options.name].show();
    },
    hide() {
      this.$refs[this.$options.name].hide();
    },
    submitPaymentMethodForm() {
      this.stripeResponseError = "";
      this.submitting = true;

      this.stripeElements.submit().then(result => {
        if (result.error) {
          this.submitting = false;
        } else {
          this.stripe
            .confirmSetup({
              clientSecret: this.stripeClientSecret,
              elements: this.stripeElements,
              confirmParams: {
                return_url: this.stripeReturnUrl
              }
            })
            .then(result => {
              if (result.error) {
                this.stripeResponseError = result.error.message;
              }
              this.submitting = false;
            });
        }
      });
    },
    handleCancelClick() {
      this.$emit("hide", this);
    },
    fetchStripeClientSecret() {
      return new Promise((resolve, reject) => {
        axios({
          url:
            process.env.VUE_APP_API_BASE_URL +
            "/network/app/billing/fetch-stripe-client-secret"
        })
          .then(resp => {
            this.stripeClientSecret = resp.data.data.client_secret;
            resolve();
          })
          .catch(err => {
            console.log(err);
            reject();
          });
      });
    }
  }
};
</script>

<style scoped lang="scss"></style>
