<template>
  <q-dialog :ref="$options.name" no-esc-dismiss no-backdrop-dismiss>
    <q-card style="min-width: 500px">
      <q-card-section class="flex items-center justify-start">
        <div>
          <h5>Edit Payment Method</h5>
          <p v-if="advertiserAccount" class="text-font-secondary">
            {{ advertiserAccount.name }} - {{ advertiserAccountId }}
          </p>
        </div>
      </q-card-section>

      <Loader v-if="loading" />
      <div v-else>
        <q-card-section class="q-pt-none">
          <p class="q-mb-sm">
            Select from one of the available payment methods below:
          </p>
          <p
            v-if="paymentMethodOptions.length == 0"
            class="q-mb-sm text-subtle"
          >
            No payment methods available.
          </p>

          <SelectionTiles
            v-model="selectedPaymentMethodId"
            :options="paymentMethodOptions"
            :disabled="submitting"
            vertical
            custom-options-content
            style="max-height: 40dvh; overflow: auto; display: block"
          >
            <template v-slot:option="option">
              <q-card-section class="row items-center q-py-sm">
                <PaymentMethodIcon
                  :brand="option.card.brand"
                  class="q-mr-md"
                  size="42"
                />
                <div>
                  <p class="text-weight-medium" v-html="option.label" />
                  <Label :text="option.description" />
                </div>

                <q-space />

                <q-btn
                  v-if="selectedPaymentMethodId != option.id"
                  round
                  dense
                  flat
                  icon="sym_r_delete"
                  @click.prevent.stop="() => promptDeletePaymentMethod(option)"
                  color="subtle"
                  :disable="option.linked_advertiser_accounts > 0"
                >
                  <q-tooltip anchor="center left" self="center right">
                    <span v-if="option.linked_advertiser_accounts > 0">
                      Payment method is active on
                      {{ option.linked_advertiser_accounts }} accounts.
                    </span>
                    <span v-else>Remove Payment Method</span>
                  </q-tooltip>
                </q-btn>
              </q-card-section>
              <!--<div v-if="selectedPaymentMethodId == option.id" class="flex items-center justify-start q-mx-sm q-pb-sm">
                <small>
                  <q-checkbox v-model="makeDefaultPaymentMethod" label="Use as the default payment method for all new accounts." size="xs" />
                </small>
              </div>-->
            </template>
          </SelectionTiles>

          <q-btn
            flat
            @click="() => promptAddNewPaymentMethod()"
            label="Add Payment Method"
            color="primary"
            :disable="submitting"
            class="q-mt-sm"
          />
        </q-card-section>
      </div>

      <q-card-actions align="between" class="q-gutter-x-md q-pa-md">
        <q-btn
          flat
          @click="handleCancelClick"
          label="Cancel"
          color="subtle"
          v-close-popup
          :disable="submitting"
        />

        <q-btn
          @click="savePaymentMethod"
          label="Save Payment Method"
          color="primary"
          :disable="submitting || paymentMethodOptions.length == 0"
          :loading="submitting"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import axios from "axios";
import Loader from "@/components/UI/Loader.vue";
import SelectionTiles from "@/components/UI/SelectionTiles.vue";
import PaymentMethodIcon from "@/components/UI/PaymentMethodIcon.vue";
import Label from "@/components/UI/Label.vue";
import { Dialog } from "quasar";
import ConfirmationModalDelete from "@/components/UI/ConfirmationModalDelete.vue";
import BusinessAddPaymentMethodModal from "@/components/Advertiser/BusinessAddPaymentMethodModal.vue";

export default {
  name: "AdvertiserAccountPaymentMethodManagementModal",
  components: { Label, PaymentMethodIcon, SelectionTiles, Loader },
  props: {
    advertiserAccountId: {
      type: [Number, String],
      required: true
    },
    activeStripePaymentMethodId: {
      type: String,
      default: ""
    }
  },
  emits: ["ok", "hide"],
  data() {
    return {
      loading: true,
      submitting: false,
      advertiserAccount: null,
      selectedPaymentMethodId: this.activeStripePaymentMethodId,
      paymentMethodOptions: [],
      makeDefaultPaymentMethod: true
    };
  },
  mounted() {
    this.loading = true;

    // this is only needed for getting the name
    this.$store
      .dispatch("advertiserAccounts/GET_REQUEST", {
        params: {
          id: this.advertiserAccountId
        }
      })
      .then(data => {
        if (data.length > 0) {
          this.advertiserAccount = data[0];
        }
      });

    this.populatePaymentMethods().then(() => {
      this.loading = false;
    });
  },
  methods: {
    show() {
      this.$refs[this.$options.name].show();
    },
    hide() {
      this.$refs[this.$options.name].hide();
    },
    savePaymentMethod() {
      this.submitting = true;

      axios
        .post(
          process.env.VUE_APP_API_BASE_URL +
            "/network/app/billing/update-advertiser-account-active-payment-method",
          {
            id: this.advertiserAccountId,
            stripe_payment_method_id: this.selectedPaymentMethodId
          }
        )
        .then(() => {
          this.triggerActionOutcomeAlert("save", true);
          this.$emit("ok", this);
          this.hide();
        })
        .catch(err => {
          this.triggerActionOutcomeAlert("save", false);
          console.log(err);
        })
        .finally(() => {
          this.submitting = false;
        });
    },
    handleCancelClick() {
      this.$emit("hide", this);
      // this.hide();
    },
    populatePaymentMethods() {
      return new Promise((resolve, reject) => {
        axios({
          url:
            process.env.VUE_APP_API_BASE_URL +
            "/network/app/billing/available-stripe-payment-methods"
        })
          .then(resp => {
            let availablePaymentMethods = resp.data.data;
            availablePaymentMethods.forEach((paymentMethod, i) => {
              availablePaymentMethods[i].active_advertiser_payment_method =
                paymentMethod.id == this.selectedPaymentMethodId;
              availablePaymentMethods[i].value = paymentMethod.id;
              availablePaymentMethods[i].label =
                this.capitalizeText(paymentMethod.card.brand) +
                " &bull;&bull;&bull;&bull; " +
                paymentMethod.card.last4;
              availablePaymentMethods[i].description =
                "Expires " +
                paymentMethod.card.exp_month +
                "/" +
                paymentMethod.card.exp_year;
            });
            this.paymentMethodOptions = availablePaymentMethods;

            resolve();
          })
          .catch(err => {
            console.log(err);
            reject();
          });
      });
    },
    promptDeletePaymentMethod(paymentMethodOption) {
      Dialog.create({
        component: ConfirmationModalDelete,
        componentProps: {
          descriptor: paymentMethodOption.label
        }
      }).onOk(dialog => {
        dialog.submitting = true;

        axios
          .post(
            process.env.VUE_APP_API_BASE_URL +
              "/network/app/billing/delete-stripe-payment-method",
            {
              stripe_payment_method_id: paymentMethodOption.id
            }
          )
          .then(() => {
            this.triggerActionOutcomeAlert("delete", true);
            this.populatePaymentMethods();
            dialog.hide();
          })
          .catch(err => {
            this.triggerActionOutcomeAlert("delete", false);
            console.log(err);
          })
          .finally(() => {
            dialog.submitting = false;
          });
      });
    },
    promptAddNewPaymentMethod() {
      Dialog.create({
        component: BusinessAddPaymentMethodModal,
        componentProps: {
          stripeReturnUrl:
            "http://localhost:8080/advertiser/admin/billing?edit_payment_method=" +
            this.advertiserAccountId
        }
      });
    }
  }
};
</script>

<style scoped lang="scss"></style>
