<template>
  <div>
    <PageTitlebar
      title="Billing"
      title-icon="sym_r_settings"
      sub-title="Manage payment methods and view your payment history."
    />

    <!-- TODO: Add something to router (or similar) that prevents navigation to "/admin" pages for non-admin users. -->
    <!--<div
      v-if="$store.state.user.record.type !== 'admin'"
      class="column justify-center items-center q-mt-xl"
    >
      <h5 class="q-mb-sm">
        Insufficient Privileges.
      </h5>
      <p class="text-center text-font-secondary">
        You must be an admin user to access this page.
      </p>
    </div>-->

    <DataTable
      table-key="advertiserAdminBillingOverview"
      row-key="id"
      title=""
      :columns="columns"
      :rows="billingByAccounts"
      :pagination="{
        sortBy: 'account',
        descending: false,
        page: 1,
        rowsPerPage: 10
      }"
      :loading="loading"
      spinner-loader
      :downloadable="false"
      :refreshable="false"
      :customizable="false"
      :filterable="true"
      :searchable="true"
      :disable-state="true"
    >
      <!-- TODO: Convert this into a component? so can reuse in tables and pagetitlebars (import history, billing history) as needed -->
      <template v-slot:account="row">
        <div class="flex items-center no-wrap justify-between">
          <div class="flex items-center no-wrap">
            <div>
              <span>{{ row.advertiser_account_name }}</span>
              <div class="flex items-center">
                <Label :text="row.advertiser_account_id" />
                <q-badge
                  v-if="row.business_id != $store.state.business.record.id"
                  color="gray-light"
                  text-color="gray-medium-dark"
                  :label="'Shared by ' + row.business_name"
                  class="q-ml-sm"
                />
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:payment_method="row">
        <div
          v-if="row.stripe_payment_method_id.length > 0"
          class="flex items-center q-gutter-x-md"
        >
          <PaymentMethodIcon :brand="row.payment_method_brand" />
          <div>
            <p>
              {{ capitalizeText(row.payment_method_brand) }}
              &bull;&bull;&bull;&bull; {{ row.payment_method_last_four }}
            </p>
            <Label
              :text="
                'Expires ' +
                  $dayjs(row.payment_method_expiration).format('MM/YY')
              "
            />
          </div>
        </div>
        <span v-else>
          <small>
            <a
              href="javascript:void(0)"
              @click="
                () => promptPaymentMethodManager(row.advertiser_account_id, '')
              "
            >
              Add Payment Method
            </a>
            <q-tooltip anchor="center right" self="center left">
              Add a payment method for this account. We will automatically
              <br />charge the payment method for any future payments.
            </q-tooltip>
          </small>
        </span>
      </template>

      <template v-slot:actions="row">
        <ActionsDropdownButton
          :actions="[
            {
              action: () =>
                promptPaymentMethodManager(
                  row.advertiser_account_id,
                  row.stripe_payment_method_id.length > 0
                    ? row.stripe_payment_method_id
                    : ''
                ),
              label: 'Edit Payment Method',
              icon: 'sym_r_edit'
            },
            {
              action: () =>
                this.$router.push({
                  name: 'AdvertiserAccountBillingHistory',
                  params: {
                    advertiserAccountId: row.advertiser_account_id
                  }
                }),
              label: 'View Billing History',
              icon: 'sym_r_history'
            }
          ]"
        />
      </template>
    </DataTable>
  </div>
</template>

<script>
import PageTitlebar from "@/components/UI/PageTitlebar";
import DataTable from "@/components/UI/DataTable.vue";
import Label from "@/components/UI/Label.vue";
import ActionsDropdownButton from "@/components/UI/ActionsDropdownButton.vue";
import PaymentMethodIcon from "@/components/UI/PaymentMethodIcon.vue";
import { Dialog } from "quasar";
import AdvertiserAccountPaymentMethodManagementModal from "@/components/Advertiser/AdvertiserAccountPaymentMethodManagementModal.vue";
import axios from "axios";

export default {
  name: "AdvertiserUserAccessOverview",
  components: {
    PaymentMethodIcon,
    ActionsDropdownButton,
    Label,
    DataTable,
    PageTitlebar
  },
  data() {
    return {
      loading: true,
      submitting: false,
      billingByAccounts: [],
      columns: [
        {
          name: "id",
          label: "Account ID",
          sort: (a, b) => this.sortInteger(a, b),
          field: "advertiser_account_id",
          type: "dimension",
          visible: false
        },
        {
          name: "name",
          label: "Account Name",
          field: "advertiser_account_name",
          type: "dimension",
          visible: false
        },
        {
          name: "account",
          label: "Account",
          field: "advertiser_account_id",
          sort: (a, b) => this.sortInteger(a, b),
          type: "dimension",
          hideField: true,
          filterable: false
        },
        {
          name: "payment_method",
          label: "Payment Method",
          field: row =>
            row.stripe_payment_method_id.length > 0
              ? this.capitalizeText(row.payment_method_brand) +
                " Ending in " +
                row.payment_method_last_four
              : "-",
          sort: (a, b) => this.sortInteger(a, b),
          type: "dimension",
          hideField: true
        },
        {
          name: "current_balance",
          label: "Current Balance",
          field: "current_balance",
          format: value =>
            !value || value == "-"
              ? "-"
              : this.numberFormatter(value, {
                  style: "currency",
                  currency: "USD"
                }),
          sortable: true,
          sort: (a, b) => this.sortMoney(a, b),
          type: "metric",
          metricType: "number",
          style: "width: 40px"
        },
        {
          name: "actions",
          label: "",
          field: "",
          filterable: false,
          sortable: false,
          align: "right"
        }
      ]
    };
  },
  computed: {},
  mounted() {
    this.populateAccountsBilling();
  },
  methods: {
    populateAccountsBilling() {
      this.loading = true;

      axios({
        url:
          process.env.VUE_APP_API_BASE_URL +
          "/network/app/billing/advertiser-account-active-payment-methods",
        params: {}
      })
        .then(resp => {
          let accountsActivePaymentMethods = resp.data.data;

          // If coming to this page after adding new payment method (or other), prompt the payment method manager
          if (this.$route.query.edit_payment_method) {
            // TODO: Fix issue with this being prompted twice, due to mounted being called twice (which i believe is due to multiple vue-router issue)
            let accountToPrompt = accountsActivePaymentMethods.find(
              account =>
                account.advertiser_account_id ==
                this.$route.query.edit_payment_method
            );
            this.promptPaymentMethodManager(
              accountToPrompt.advertiser_account_id,
              accountToPrompt.stripe_payment_method_id
            );
            this.removeUrlParamsFromWindow([
              "edit_payment_method",
              "setup_intent",
              "setup_intent_client_secret",
              "redirect_status"
            ]);
          }

          // Populate the current balance for each account from reporting
          this.$store
            .dispatch("advertiserReporting/POST_REQUEST", {
              params: {
                report_payload: {
                  start_date: this.$dayjs()
                    .tz()
                    .startOf("month")
                    .format("YYYY-MM-DD"),
                  end_date: this.$dayjs()
                    .tz()
                    .format("YYYY-MM-DD"),
                  options: {
                    include_results_name_fields: true,
                    include_results_id_fields: true,
                    dimension_key_indexed_results: false,
                    format_results: false,
                    include_totals: true,
                    include_time_last_updated: true,
                    pagination: {
                      disabled: true
                    }
                  },
                  dimensions: {
                    advertiser_account: []
                  },
                  metrics: {
                    spend: []
                  }
                }
              }
            })
            .then(report => {
              let reportRows = "rows" in report ? report.rows : [];
              reportRows.forEach(reportRow => {
                let thisAccountIndex = accountsActivePaymentMethods.findIndex(
                  account =>
                    account.advertiser_account_id ==
                    reportRow.advertiser_account_id
                );
                accountsActivePaymentMethods[thisAccountIndex] = {
                  ...accountsActivePaymentMethods[thisAccountIndex],
                  current_balance: reportRow.spend
                };
              });

              this.billingByAccounts = accountsActivePaymentMethods;
              this.loading = false;
            });
        })
        .catch(err => {
          console.log(err);
        });
    },
    promptPaymentMethodManager(
      advertiserAccountId,
      activeStripePaymentMethodId
    ) {
      Dialog.create({
        component: AdvertiserAccountPaymentMethodManagementModal,
        componentProps: {
          advertiserAccountId: advertiserAccountId,
          activeStripePaymentMethodId: activeStripePaymentMethodId
        }
      }).onOk(() => {
        this.populateAccountsBilling();
      });
    }
  }
};
</script>

<style scoped lang="scss"></style>
