<template>
  <img
    v-if="brandLogo"
    :src="brandLogo"
    :style="'width: ' + size + 'px; align-self: center;'"
  />
  <q-icon v-else size="30px" name="sym_r_credit_card" color="subtle" />
</template>

<script>
export default {
  name: "PaymentMethodIcon",
  props: {
    brand: {
      type: String,
      required: true
    },
    size: {
      type: [Number, String],
      default: "32"
    }
  },
  data() {
    return {};
  },
  computed: {
    brandLogo() {
      if (
        [
          "amex",
          "visa",
          "mastercard",
          "discover",
          "jcb",
          "maestro",
          "unionpay"
        ].includes(this.brand)
      ) {
        return require("@/assets/images/payment-methods/" +
          this.brand +
          ".svg");
      }
      return null;
    }
  }
};
</script>

<style scoped lang="scss"></style>
